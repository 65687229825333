import styles from "./home.module.css";

import photo1 from "../../assets/home5.png";
import photo2 from "../../assets/home6.png";
import carousel from "../../assets/carousel.jpg";

const FourthSection = () => {

  return (
    <div>
      <img className={styles.img_carousel} src={carousel} alt="" /> 
      <div className={`${styles.about} container sections-padding`}>
        <div
          className={styles.text}>
          <p className="paragraph">About</p>
          <h2>
            Fitness Flame Nutrition
            <br />
            Fuel Your Body
          </h2>
          <p>
            We fuel the flame within you, yes, we are India’s most promising startup in the field of nutrition with the only ambition to provide you the best nutrition for your body at an affordable price. Our edible products flaunt zero adulteration and are of the most nutritional value without compromising with the taste.
          </p>
          <div className={styles.text_one}>
            <div>
              <h3>Motivation</h3>
              <p>
                Supplements serve as a vital component in fitness and nutrition, primarily due to their role in muscle growth and recovery. Whether consumed post-workout or as a snack, they provide flexibility in dietary planning. Quality assurance ensures purity and integrity, supporting overall health and wellness by contributing to essential bodily functions like hormone production and tissue repair.
              </p>
            </div>
            <img src={photo1} alt="Photoone"  rel="noreferrer"/>
          </div>
          <div className={styles.text_two}>
            <img src={photo2} alt="Phototwo"  rel="noreferrer"/>
            <div>
              <h3>Inspire</h3>
              <p>Staying fit isn't just about physical appearance; it's a commitment to your overall well-being. Find inspiration in the joy of movement, the thrill of progress, and the empowerment of taking care of yourself. Remember, every workout is a step toward a healthier, happier you. Stay motivated by focusing on how exercise makes you feel—stronger, more energized, and capable of taking on life's challenges with confidence. </p>
            </div>
          </div>
        </div>
        {/* <div className={styles["img-container"]}>
          <img src={photo3} alt="Man Exercise" />
          <button onClick={() => navigate("/schedule")}>Get Started</button>
        </div> */}
      </div>
    </div>
  );
};

export default FourthSection;
