import { useNavigate } from "react-router";
import styles from "./home.module.css";

const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <div className={styles["hero-section"]} data-aos="zoom-out-down">
      <h1 className={`${styles["main-heading"]} head`}>
        FUEL YOUR FLAME
      </h1>
      <h5 className="sub-heading">Welcome to Fitness Flame Nutrition,<br/>Bringing you the best nutrition at your doorstep</h5>
      <div>
        <button onClick={() => navigate("/products")}>Go Shopping</button>
        {/* <button>About Me</button> */}
      </div>
    </div>
  );
};

export default HeroSection;
