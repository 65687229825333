import HeroSection from "./HeroSection";
// import FitnessTraining from "../fitnessTraining/FitnessTraining";
import Panner from "./Panner";
import Benefits from "./Benefits";
import About from "./About";
import Swiper from "./Swiper";
import Trainers from "../trainers/Trainers";
import Products from "../products/Products";
// import GymAmenities from "./GymAmenities";
import OurPlans from "./OurPlans";
import ArticlesNews from "./ArticlesNews";
import ReactGA from "react-ga4";
import { useState } from "react";
import offerImg from "../../assets/popup.jpg";
import styles from "./home.module.css";

const Home = () => {
  ReactGA.send({ hitType: "pageview", page: "/home", title: "Home" });
  const [dialog, setDialog] = useState(true);
  const [imageSrc, setImageSrc] = useState(offerImg);
  const toggleDialog = () => {
    setDialog(!dialog);
  };

  return (
    <div>
    {dialog && (
      <div id="myModal" className={styles.modal}>
        <span className={styles.close} onClick={toggleDialog}>&times;</span>
        <img className={styles.modal_content} id="img01" alt="popup" src ={imageSrc}/>
        <div id="caption"></div>
      </div>
    )}
    {!dialog && <div>
      <HeroSection />
      <Panner />
      <Products />
      {/* <FitnessTraining /> */}
      <About />
      <Benefits />
      <Swiper />
      <Trainers />
      {/* <GymAmenities /> */}
      <OurPlans />
      <ArticlesNews />
    </div> }
    </div>
  );
};

export default Home;
