import { articlesNewsData } from "../../data/data";
import styles from "./home.module.css";

const ArticlesNews = () => {
  const navigate = (link) => {
    window.open(link, "_blank");
  }

  return (
    <div className={`${styles["articles-news"]} container sections-padding`}>
      <h2>Articles & News</h2>

      {articlesNewsData.map((ele) => {
        return (
          <div key={ele.id} data-aos="zoom-out-right">
            <span>{ele.date}</span>
            <span>{ele.about}</span>
            <h3>{ele.title}</h3>
            <p>
              {ele.shortInfo}
            </p>
            <button onClick={() => navigate(ele.link)}>Read More</button>
          </div>
        );
      })}
    </div>
  );
};

export default ArticlesNews;
