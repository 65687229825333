import styles from "./footer.module.css";
import logoIcon from "../../assets/whiteIcon.jpg";
// import { useNavigate } from "react-router";

const Footer = () => {
  // const navigate = useNavigate();

  return (
    <div className={styles.footer}>
      <div className={styles.footer_image}>
        <h2>
          Follow us on
          <a
            href="https://www.instagram.com/fitnessflamenutrition/"
            target="_blank" rel="noreferrer">
            Instagram
          </a>
        </h2>
      </div>
      <div className={`${styles.footer_content} container `}>
        <div>
          <div className={styles.logo_holder}>
            <img src={logoIcon} alt="Logo Icon" />
            <div className={styles.footer_icons}>
              <a
                href="https://www.instagram.com/fitnessflamenutrition/"
                target="_blank"  rel="noreferrer">
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a href="https://www.youtube.com/@shergillfitness" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-youtube"></i>
              </a>
              <a href="https://www.linkedin.com/in/fitness-flame-team" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-linkedin"></i>
              </a>
              {/* <a href="mailto:support@fitnessflamenutrition.com" target="_blank" rel="noreferrer">
                <i className="fa-brands fa-envelope"></i>
              </a> */}
            </div>
          </div>
          <div className={styles.footer_span}><span><p>© Fitness Flame <br/> All Rights Reserved</p></span></div>
          <ul>
            {/* <li>Utility Pages</li>
            <li onClick={() => navigate("/calculator")}>BMI Calculator</li> */}
            {/* <li onClick={() => navigate("/schedule")}>Schedule</li> */}
            {/* <li onClick={() => navigate("/errorPage")}>404 Page</li> */}
            {/* <li>Password Protected</li> */}
            {/* <li>Licenses</li> */}
            {/* <li onClick={() => navigate("/contact")}>Contact Us</li> */}
          </ul>
        </div>
        <div>
          <div className={styles.info}>
            <span>Call :</span>
            <p>+91 911-517-0676</p>
            <span>Email :</span>
            <p className={styles.wraptext}>support@fitnessflamenutrition.com</p>
          </div>
        </div>
        <div>
          <div className={styles.info}>
            <span>Directions :</span>
            <p><a href="https://www.google.com/maps/place/Fitness+Flame+Nutrition/@30.6517169,76.8033924,17z/data=!3m1!4b1!4m6!3m5!1s0x390feb5735698897:0x783122bb9403659e!8m2!3d30.6517169!4d76.8033924!16s%2Fg%2F11s7z1vs7f?entry=ttu" target="_blank">Fitness Flame Nutrition</a></p>
            <p className={styles.wraptext}>SCO-2, First Floor, Bhabat Rd, Shiva Enclave, Nabha, Zirakpur, Punjab 140603</p>
          </div>
        </div>
        <div>
          {/* <h3>Newsletter</h3>
          <input type="text" placeholder="Enter Your Email" />
          <button>Subscribe</button> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
